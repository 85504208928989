import React, { useState } from 'react';

import TrialButton from './TrialButton';

const reviews = [
  {
    reviewer: {
      displayName: 'Harinder Kainth',
    },
    starRating: 5,
    comment:
      'Professionally run world class Brazilian jiujitsu academy and instructors, could not recommend highly enough for anyone looking to learn self defence, gain confidence, get fit and healthy in a fun safe environment.',
    createTime: 'a week ago',
  },

  {
    reviewer: {
      displayName: 'Stephanie Khiara',
    },
    starRating: 5,
    comment:
      'I started Brazilian Jiu Jitsu in September 2019. I was lucky enough to meet Professor Luciano very early on in my BJJ journey. I did a mixture of group classes and private lessons to speed up my learning. I came to BJJ relatively late in life and now wish I’d started 20 years earlier!\nLuciano is an exceptional teacher, he’s very caring, encouraging, with a meticulous attention to detail and very inspiring. He’s learnt from the best and he lives and breathes Jiu Jitsu!\nI’ve also watched him teach kids classes and he’s amazing. It seems great fun whilst also being ‘proper’ Jiu Jitsu (not just games) and the kids respond well and seem to love it. I highly recommend Luciano to anyone who wants to train in Jiu Jitsu.',
    createTime: 'a week ago',
  },
  {
    reviewer: {
      displayName: 'Max Feller',
    },
    starRating: 5,
    comment:
      "Highly recommend. Luciano is one of the best Brazilian Jiu Jitsu Professors  in the world. He's a fantastic teacher who really cares about his students.",
    createTime: 'a week ago',
  },
];

const TestimonialComponent = ({ text, name, imageIndex }) => {
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => {
    setExpanded(!expanded);
  };
  const shortText = text.length < 220;
  const displayText =
    expanded || shortText ? text : text.substring(0, 220) + ' ...';

  const wrapperPointer = shortText ? '' : 'cursor-pointer';
  const imageSrcs = [
    'images/newKidsClass.jpg',
    'images/adult_class_2.jpeg',
    'images/adult_class_3.JPG',
  ];
  return (
    <div className="hero min-h-[400px] w-full testimonialsWrapper">
      <div className="flex-col gap-12 hero-content lg:flex-row lg:w-full ">
        <img
          src={imageSrcs[imageIndex]}
          className="rounded-lg shadow-2xl md:max-w-sm"
        />

        <div
          role="listitem"
          className={`bg-white shadow rounded p-4 xl:p-8 relative z-10  ${wrapperPointer} hover:bg-gray-100 max-w-[400px]`}
          onClick={handleClick}
        >
          <img
            src="https://cdn.tuk.dev/assets/components/26May-update/quote.png"
            aria-hidden="true"
            alt="quote-png"
          />
          <div className="flex items-start justify-between pt-4 pl-4">
            <div className="mr-6">
              <p className="text-gray-600 xl:text-xl font-r">{displayText}</p>
              <p className="mt-4 text-base leading-none text-gray-800 font-m">
                {name}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Testimonials() {
  return (
    <div className="px-4 py-12 xl:mx-auto xl:container xl:px-20 md:px-6">
      <div className="hero min-h-[200px]">
        <div className="text-center hero-content">
          <div className="w-full md:w-[500px]">
            <h1 className="text-3xl font-bold md:text-5xl">
              What our students say
            </h1>
            <TrialButton className="py-4 mt-6 " />
          </div>
        </div>
      </div>
      <div
        role="list"
        aria-label="Testimonials"
        className="flex flex-col w-full "
      >
        {reviews.map((review, index) => {
          return (
            <TestimonialComponent
              key={index}
              imageIndex={index}
              name={review.reviewer.displayName}
              text={review.comment}
            />
          );
        })}
      </div>
    </div>
  );
}
