import React from 'react';
export default function ContactButton({ className, handleModalOpen }) {
  return (
    <>
      <button
        onClick={handleModalOpen}
        className={`focus:outline-none leading-none text-gray-900 bg-white font-b rounded-lg py-4 px-7 ${className}`}
      >
        CONTACT
      </button>
    </>
  );
}
