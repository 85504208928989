import React from 'react';

export default function LucianoInstructor({ header, description, image }) {
  const Header = ({ text }) => {
    return (
      <h1 className='text-2xl font-black tracking-tighter lg:text-6xl md:leading-snug f-f-l'>
        {text}
      </h1>
    );
  };

  const Description = ({ text }) => {
    return (
      <p className='py-2 text-lg leading-7 lg:text-3xl md:leading-10 f-f-r'>
        {text}
      </p>
    );
  };
  return (
    <div className='flex flex-col gap-12 md:gap-6 w-full items-center justify-evenly lg:flex-row instructorWrapperDiv'>
      <img
        src={image}
        alt='instructor'
        className='w-[90%] lg:w-[50%] rounded max-w-[450px] instructorPicture md:h-full px-2 sm:px-12'
      />
      <div className='flex flex-col w-full w-[100%] md:w-[80%] lg:w-[40%]  lg:min-h-[450px] copyBitBob h-full justify-evenly  gap-12 md:gap-6'>
        {header && <Header text={header} />}
        {description && <Description text={description} />}
      </div>
    </div>
  );
}
