import React from 'react';
import { SpinnerCircular } from 'spinners-react';

export default function Sending() {
  return (
    <div className='px-4 md:px-10 pt-6 md:pt-12 md:pb-4 pb-7 flex flex-col items-center justify-center min-h-[300px]'>
      <SpinnerCircular color='#932933' />
    </div>
  );
}
