export const classes = [
  {
    name: 'Introduction',
    description:
      "Introduction class is designed to explain the rules of BJJ, the structures of the classes and what to expect in a group class. It's a one-time class and is recommended for those who don't have any experience with BJJ. For those who have done BJJ before, we recommend trying our Fundamentals class as a trial.",
  },
  {
    name: 'Fundamentals',
    description:
      'Fundamentals classes create a strong foundation for those new to Brazilian Jiu-Jitsu as well as perfecting techniques for more advanced players. Students will learn basic techniques, positional awareness and core concepts in every position.',
  },
  {
    name: 'No Gi',
    description:
      'As the name suggests, no-gi training is undertaken without the gi uniform. This style of grappling is generally faster and more dynamic than gi grappling. Including positions and submissions that are not allowed and/or not available in the gi.',
  },
  {
    name: 'Trial Class',
    description:
      "Potential students are welcome to try out one of our classes for free. Just click the FREE TRIAL CLASS button and complete the form you'll find there.",
  },
  {
    name: 'All Levels',
    description:
      'All levels of BJJ is designed to challenge your ways of thinking, movements, strength and conditioning. These classes are usually longer and more intense than Fundamentals Classes and start to challenge the skills of attendees. These also start to introduce the competitive sportive nature of BJJ and submission grappling, allowing for more advanced techniques against skilled opponents.',
  },
  {
    name: 'Kids BJJ (4-15yo)',
    description:
      "Brazilian Jiu-Jitsu classes are for 4-15 year-olds and are friendly and educational. They give children a chance to make new friends and learn new skills. They also see the importance of working with others and respecting fellow students' needs and abilities. Each lesson starts with a warm-up consisting of a mix of stretching (to improve flexibility) and some cardio exercises. Depending on students' ages and abilities, we then cover basic Brazilian Jiu-Jitsu moves and, for older ones, look at how certain combinations are put together. These lessons are designed for children to develop their confidence, self-esteem, balance, concentration, cooperation, coordination and, of course, fitness. Sessions are a great addition to any child's education, and we hope that what they learn here will positively impact other areas of their lives. Parents are encouraged to stay and watch the lessons and see what progress is being made. Sessions are led by Luciano Cristovam, who is a Brazilian Jiu-Jitsu black belt and has many years of experience teaching children and young people. Alongside coach Renan, coach Miles, coach Joao and coach Aitch.",
  },
  {
    name: 'Open Mat',
    description:
      "Open mat time is when you can come to the gym, and there is no instructor and no set class, but the mats are 'Open' for you and your teammates to use.",
  },
  {
    name: 'Competition Class',
    description:
      'Not everyone chooses to compete, but for those who do - or like to train as they do - this class is for you. Our Competition Class is a rigorous, high-intensity class oriented toward the jiu-jitsu athlete. It is focused less on teaching technique and more on sharpening your skills and competitive acuity, with a lot of fast-paced positional sparring and live rolling.',
  },
];
