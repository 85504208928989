import React from 'react';
import Medal from '../svgs/Medal';
export default function MedalCTA({ image, competitions, title }) {
  return (
    <div className='pt-10 md:pt-40 xl:mx-auto xl:container xl:px-24'>
      <div className='container mx-auto'>
        <div className='flex flex-wrap items-center pb-12'>
          <div className='w-full md:w-1/2 lg:w-2/3 xl:pr-20 md:pr-6'>
            <div className='py-2 text-color'>
              <p className='text-2xl font-black tracking-tighter lg:text-6xl md:leading-snug f-f-l mb-6'>
                {title}
              </p>
              {competitions.map((comp, index) => {
                return (
                  <div key={index} className='flex items-center '>
                    <Medal />

                    <h2 className='text-lg font-semibold leading-4 text-gray-800'>
                      {comp}
                    </h2>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='relative flex items-end justify-center w-full lg:w-1/3 md:w-1/2 h-96'>
            <img
              className='absolute inset-0 object-cover object-center w-full h-full rounded-md border-12 border-red'
              src={image}
              alt='Image1'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
