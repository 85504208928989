import React from 'react';
import { FREE_TRIAL_LINK } from '../data/siteConstants';
export default function Button({ className }) {
  const handleButtonClick = () => {
    window.location.href = FREE_TRIAL_LINK;
  };
  return (
    <button
      onClick={handleButtonClick}
      className={`${className} bg-red-site focus:outline-none text-base leading-none text-white font-b rounded-lg py-4 px-7`}
    >
      FREE TRIAL CLASS
    </button>
  );
}
