export const ADDRESS = {
  TITLE: 'Luciano Cristovam BJJ',
  LINE_ZERO: 'Unit 5, Holmes Studios',
  LINE_ONE: '45 Holmes Road',
  LINE_TWO: 'London',
  POSTCODE: 'NW5 3AN',
  COUNTRY: 'UK',
};

export const HOURS = {
  MONDAY: '7am - 8pm',
  TUESDAY: '7am - 8pm',
  WEDNESDAY: '7am - 8pm',
  THURSDAY: '7am - 8pm',
  FRIDAY: '9am - 8pm',
  SATURDAY: '9am - 2pm',
  SUNDAY: '12:30pm - 2:30pm',
};

export const PROMO = {
  title: 'No Joining Fee',
  subTitle: 'For the first 3 months enjoy no joining fee. Usual price £120.',
  button: true,
  buttonText: 'JOIN NOW',
  buttonLink: 'https://www.lucianocristovam.com/pricing',
};

export const SOCIALS = {
  INSTAGRAM: 'https://www.instagram.com/lucianocristovamacademy/?hl=en',
  FACEBOOK: 'https://www.facebook.com/Luciano-Cristovam-BJJ-243076049362025/',
};

export const SITE_TITLE = {
  title: 'Luciano Cristovam',
  subTitle: 'Brazilian Jiu Jitsu',
};

export const DETAILS = {
  PHONE: '+44 7498 471 113',
  EMAIL: 'info@lucianocristovam.com',
};

export const FREE_TRIAL_LINK =
  'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622390744bea6d0ed4429c25/plan/1646497878574/buy';

export const MEMBERSHIPS = [
  {
    title: 'Adults',
    monthlySubTitle:
      'Joining fee includes LC official kimono. Unlimited membership for adults from 16 years old.',
    monthlyPrice: '£160',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622390eba82ac57ba7750377/plan/1646498011325/buy',
    annualPrice: '£1600',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622391918b6756374d6f119f/plan/1646498171089/buy',
    annualSubTitle:
      'Includes LC official kimono. Unlimited membership for adults from 16 years old.',
    dayPass: false,
    joiningFee: '£100',
    saveAnnual: '£320',
  },
  {
    title: 'Kids',
    monthlySubTitle:
      'Joining fee includes LC official kimono. Unlimited membership for kids up to 16 years old.',
    monthlyPrice: '£120',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/62239154072dbd42ce5b04d8/plan/1646498123510/buy',
    annualPrice: '£1200',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622391ab4efe556a73040590/plan/1646498208628/buy',
    annualSubTitle:
      'Includes LC official kimono. Unlimited membership for kids up to 16 years old.',
    dayPass: false,
    comingSoon: false,
    joiningFee: '£95',
    saveAnnual: '£240',
  },

  {
    title: 'Private with Prof. Luciano',
    monthlySubTitle: '1 hour private class with Professor Luciano Cristovam',
    monthlyPrice: '£200',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622519409360937baf5aac45/plan/1646598409760/buy',
    annualPrice: '£200',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/622519409360937baf5aac45/plan/1646598409760/buy',
    annualSubTitle: '1 hour private class with Professor Luciano Cristovam',
    dayPass: true,
  },
  // {
  //   title: 'Private with Coach Vinicius',
  //   monthlySubTitle: '1 hour private class with Coach Vinicius Frigo',
  //   monthlyPrice: '£120',
  //   monthlyLink:
  //     'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6387bd98687c26ba5d07af1c/plan/1669840199623/buy',
  //   annualPrice: '£120',
  //   annualLink:
  //     'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6387bd98687c26ba5d07af1c/plan/1669840199623/buy',
  //   annualSubTitle: '1 hour private class with Coach Vinicius Frigo',
  //   dayPass: true,
  // },
  {
    title: 'Private with Coach Beto',
    monthlySubTitle: '1 hour private class with Coach Beto',
    monthlyPrice: '£100',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/66146640f0273da811007b85/plan/1712612838604/buy',
    annualPrice: '£100',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/66146640f0273da811007b85/plan/1712612838604/buy',
    annualSubTitle: '1 hour private class with Coach Beto',
    dayPass: true,
  },
  {
    title: 'Private with Coach Renan',
    monthlySubTitle: '1 hour private class with Coach Renan Borges',
    monthlyPrice: '£80',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6387bf371a52a420b6010d52/plan/1669840636713/buy',
    annualPrice: '£80',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6387bf371a52a420b6010d52/plan/1669840636713/buy',
    annualSubTitle: '1 hour private class with Coach Renan Borges',
    dayPass: true,
  },

  {
    title: 'Private with Coach Miles',
    monthlySubTitle: '1 hour private class with Coach Miles Emrys',
    monthlyPrice: '£70',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/65399404a63022d7dd052030/plan/1698272188745/buy',
    annualPrice: '£70',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/65399404a63022d7dd052030/plan/1698272188745/buy',
    annualSubTitle: '1 hour private class with Coach Miles Emrys',
    dayPass: true,
  },
  // {
  //   title: 'Private with Coach João',
  //   monthlySubTitle: '1 hour private class with Coach João',
  //   monthlyPrice: '£55',
  //   monthlyLink:
  //     'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/657e3aa87565ebca7c039e9c/plan/1702770987373/buy',
  //   annualPrice: '£55',
  //   annualLink:
  //     'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/657e3aa87565ebca7c039e9c/plan/1702770987373/buy',
  //   annualSubTitle: '1 hour private class with Coach João',
  //   dayPass: true,
  // },
  {
    title: 'Yoga Class',
    monthlySubTitle: 'One off pass for Yoga Class',
    monthlyPrice: '£13',
    monthlyLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6438856191f7a7171900a66a/plan/1681425672012/buy',
    annualPrice: '£13',
    annualLink:
      'https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/memberships/6438856191f7a7171900a66a/plan/1681425672012/buy',
    annualSubTitle: 'One off pass for Yoga Class',
    dayPass: true,
  },
];
