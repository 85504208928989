export default function Certifications() {
  const certifications = [
    '/images/logos/first-aid.png',
    '/images/logos/nspcc.png',
    '/images/logos/dbs.png',
  ];
  return (
    <div className="flex flex-col items-center w-full gap-40 my-24  mx-auto max-w-[90vw] lg:max-w-[800px]">
      <div className="flex items-center justify-around w-full ">
        {certifications.map((certification, index) => {
          return (
            <img
              key={index + 'certification'}
              src={certification}
              className="h-24 mx-4 "
              alt="certification"
            />
          );
        })}
      </div>
    </div>
  );
}
