import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import Form from './Form';
import Sending from './Sending';
import Success from './Success';
import Error from './Error';
export default function ContactForm({ handleModalClose }) {
  const form = useRef();
  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userID = process.env.REACT_APP_EMAILJS_USER_ID;
  const [displayForm, setDisplayForm] = useState(true);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displaySending, setDisplaySending] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    setDisplayForm(false);
    setDisplaySending(true);
    emailjs.sendForm(serviceID, templateID, form.current, userID).then(
      () => {
        setDisplaySending(false);
        setDisplaySuccess(true);
      },
      (error) => {
        setDisplayError(false);
      }
    );
    e.target.reset();
  };

  return (
    <>
      <div
        id='popup'
        className='contactFormModal fixed w-full flex justify-center inset-0'
      >
        <div className='w-full h-full bg-gray-900 opacity-95 z-0 absolute inset-0' />
        <div className='mx-auto container'>
          <div className='flex items-center justify-center h-full w-full'>
            <div className='bg-white rounded-md shadow fixed overflow-y-auto sm:h-auto w-10/12 md:w-8/12 lg:w-1/2 2xl:w-2/5'>
              <div className='bg-gray-100 rounded-tl-md rounded-tr-md px-4 md:px-8 md:py-4 py-7 flex items-center justify-between'>
                <p className='text-base font-semibold'>Get in touch</p>
                <button
                  className='focus:outline-none'
                  onClick={handleModalClose}
                >
                  <svg
                    width={28}
                    height={28}
                    viewBox='0 0 28 28'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M21 7L7 21'
                      stroke='#A1A1AA'
                      strokeWidth='1.75'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M7 7L21 21'
                      stroke='#A1A1AA'
                      strokeWidth='1.75'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
              </div>

              {displaySuccess && <Success />}
              {displayError && <Error />}
              {displaySending && <Sending />}
              {displayForm && (
                <div className='px-4 md:px-10 pt-6 md:pt-12 md:pb-4 pb-7'>
                  <Form
                    formRef={form}
                    submitForm={sendEmail}
                    closeModal={handleModalClose}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
