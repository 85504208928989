import React from 'react';
import DownloadLinks from './DownloadLinks';
import AppMockUp from './AppMockUp';
import BusinessHours from './BusinessAddress';
import BusinessAddress from './BusinessHours';

import { ADDRESS, HOURS, DETAILS, SITE_TITLE } from '../../data/siteConstants';
import SmallLogo from '../../svgs/SmallLogo';
import InstagramLogo from '../../svgs/InstagramLogo';
import {
  CardWrapper,
  Text,
  Title,
  Copyright,
  Phone,
  Email,
  HoursTable,
  AddressWrapper,
} from './FooterComponents';
import ContactButton from '../Contact/ContactButton';
import { SOCIALS } from '../../data/siteConstants';
export default function AppDownload({ handleModalOpen }) {
  return (
    <>
      <div>
        <AppMockUp />
        <div className="px-4 pt-20 pb-5 mt-20 lg:pt-32 lg:mt-40 bg-slate-900 xl:px-0 f-f-p">
          <div className="container mx-auto">
            <div className="container grid gap-8 mx-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <div className="invisible hidden empty lg:block">234</div>
              <DownloadLinks />
              <BusinessHours />
              <BusinessAddress />
            </div>
          </div>
        </div>
        <div className="py-4 bg-slate-900">
          <div className="container mx-auto">
            <div className="flex items-center justify-center w-full mx-auto my-12 space-x-6 ">
              <ContactButton handleModalOpen={handleModalOpen} />
              <a href={SOCIALS.FACEBOOK}>
                <i className="text-2xl text-white fa-brands fa-facebook-f"></i>
              </a>
              <InstagramLogo />
            </div>
            <div className="flex items-center justify-center w-full mx-auto my-12 space-x-6 ">
              <a
                href="/anti_bullying_policy.pdf"
                target="_blank"
                className="text-white hover:underline"
              >
                Anti-Bullying Policy
              </a>
              <a
                href="/safeguarding_policy.pdf"
                target="_blank"
                className="text-white hover:underline"
              >
                Safeguarding Policy
              </a>
              <a
                href="/privacy_policy.pdf"
                target="_blank"
                className="text-white hover:underline"
              >
                Privacy Policy
              </a>
            </div>
            <h5 className="text-sm font-semibold tracking-wide text-center text-white">
              2024 © Luciano Cristovam
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}
