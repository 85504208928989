import React from 'react';
import { NavLink } from 'react-router-dom';

export default function MobileLink({ logo, title, path }) {
  return (
    <>
      <NavLink to={path}>
        <li className='pt-8 text-gray-800'>
          <div className='flex items-center'>
            <i className={`fa-solid ${logo}`}></i>

            <p className='ml-3 text-xl font-b text-gray-800 '>{title}</p>
          </div>
        </li>
      </NavLink>
    </>
  );
}
