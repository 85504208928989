import React, { useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import ContactForm from './components/Contact/ContactForm';
import AppDownload from './components/Footer/AppDownload';
export default function LayoutWrapper({ children, sessionStorageKey }) {
  const [displayContactModal, setDisplayContactModal] = useState(false);
  const handleModalOpen = () => {
    setDisplayContactModal(true);
  };
  const handleModalClose = () => {
    setDisplayContactModal(false);
  };
  return (
    <>
      <div className='absolute w-full h-full bg-white'>
        {displayContactModal && (
          <ContactForm handleModalClose={handleModalClose} />
        )}
        <Navbar
          sessionStorageKey={sessionStorageKey}
          handleModalOpen={handleModalOpen}
        />
        <div className='container flex flex-col items-start justify-between px-6 pb-4 mx-auto my-6 lg:my-12 lg:flex-row lg:items-center bg-white'>
          {children}
        </div>
        <AppDownload handleModalOpen={handleModalOpen} />
      </div>
    </>
  );
}
