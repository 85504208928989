import React from 'react';
import AnimatedLogo from '../svgs/AnimatedLogo';
import { useSpring, animated } from 'react-spring';
import Classes from '../components/Classes';
import Testimonials from '../components/Testimonials';
import Affiliations from '../components/Affiliations';
import Certifications from '../components/Certifications';
export default function Home({ sessionStorageKey }) {
  const configNav = {
    tension: 210,
    friction: 30,
    duration: 700,
  };

  const noAnimation = useSpring({});

  const fadeInSub = useSpring({
    configNav,
    delay: 1700,
    to: 1,
    from: {
      opacity: 0,
    },
  });
  return (
    <>
      <div className="w-full">
        <div className="bg-white ">
          <section className="container w-full mx-auto py-36">
            <div className="flex flex-col items-center justify-center">
              <AnimatedLogo
                sessionStorageKey={sessionStorageKey}
                className="mb-4 w-[350px] md:w-[400px]"
              />
              <div className="font-black leading-snug text-center text-gray-800 lg:w-3/4">
                <animated.h2
                  style={sessionStorageKey ? noAnimation : fadeInSub}
                  className="text-3xl md:text-4xl"
                >
                  Luciano Cristovam
                </animated.h2>
                <animated.h6
                  style={sessionStorageKey ? noAnimation : fadeInSub}
                  className="text-xl md:text-2xl font-l"
                >
                  Brazilian Jiu Jitsu
                </animated.h6>
              </div>
            </div>
          </section>

          <Classes style={sessionStorageKey ? noAnimation : fadeInSub} />
          <Affiliations />
          <Testimonials />
          <Certifications />
        </div>
      </div>
    </>
  );
}
