import React from 'react';
import { HOURS } from '../../data/siteConstants';
import { HoursTable } from './FooterComponents';
export default function BusinessHours() {
  return (
    <div className='text-white flex flex-col lg:items-center'>
      <div>
        <h2 className='text-2xl font-semibold'>Hours</h2>
        <HoursTable data={HOURS} />
      </div>
    </div>
  );
}
