import React, { useState } from 'react';
import { MEMBERSHIPS } from '../data/siteConstants';
import TrialButton from '../components/TrialButton';
const PricingComponent = ({
  title,
  monthlySubTitle,
  monthlyPrice,
  annualPrice,
  annualLink,
  annualSubTitle,
  monthlyLink,
  showAnnualPrice,
  dayPass,
  comingSoon,
  joiningFee,
  saveAnnual,
}) => {
  const displayPrice = showAnnualPrice ? annualPrice : monthlyPrice;
  const monthlyOrAnnual = showAnnualPrice ? 'year' : 'mo';
  const link = showAnnualPrice ? annualLink : monthlyLink;
  const subTitle = showAnnualPrice ? annualSubTitle : monthlySubTitle;

  const handlePricingClick = () => {
    window.location.href = link;
  };

  const subPartFigure = showAnnualPrice ? saveAnnual : joiningFee;
  const subPartCopy = showAnnualPrice ? 'save ' : '+ joining fee ';
  return (
    <div
      role='listitem'
      onClick={handlePricingClick}
      className='bg-white cursor-pointer shadow hover:shadow-slate-200 hover:shadow-lg hover:bg-gray-100 rounded-lg p-8 relative z-30 my-6'
    >
      {comingSoon && (
        <div className='absolute w-full h-full top-0 left-0 z-50 rounded-lg bg-gray-200 opacity-80 flex items-center justify-center text-center'>
          <h1 className='font-bi text-3xl lg:text-5xl'>COMING APRIL 2022</h1>
        </div>
      )}
      <div className='md:flex items-center justify-between'>
        <h2 className='text-2xl font-semibold leading-6 text-gray-800'>
          {title}
        </h2>
        <div className='flex flex-col md:items-end'>
          <p className='text-2xl font-semibold md:mt-0 mt-4 leading-6 text-gray-800'>
            {displayPrice}
            {dayPass ? (
              <span className='font-normal text-base'>/hour</span>
            ) : (
              <span className='font-normal text-base'>/{monthlyOrAnnual}</span>
            )}
          </p>
          {joiningFee && (
            <p className='text-lg font-semibold md:mt-0 mt-0 leading-6 text-gray-800/60'>
              <span className='font-normal text-base'>{subPartCopy}</span>
              {subPartFigure}
            </p>
          )}
        </div>
      </div>
      <p className='md:w-80 text-base leading-6 mt-4 text-gray-600'>
        {subTitle}
      </p>
    </div>
  );
};

export default function Pricing() {
  const [showAnnualPrice, setShowAnnualPrice] = useState(false);

  const handlePriceToggleClick = () => {
    setShowAnnualPrice(!showAnnualPrice);
  };

  const activeToggleClassName =
    'bg-slate-700 focus:outline-none text-base leading-none text-white font-b rounded-full py-4 px-7';

  const inActiveToggleClassName =
    'bg-gray-100 focus:outline-none text-base leading-none text-black font-r rounded-full py-4 px-6';

  const annualClassName = showAnnualPrice
    ? activeToggleClassName
    : inActiveToggleClassName;

  const monthlyClassName = showAnnualPrice
    ? inActiveToggleClassName
    : activeToggleClassName;
  return (
    <>
      <div className='xl:mx-auto xl:container py-20 2xl:px-0 px-6 mt-4'>
        <div className='lg:flex items-center justify-between'>
          <div className=' lg:w-1/2 w-full'>
            <p className='text-base leading-4 text-gray-600'>
              Choose your plan
            </p>
            <h1 className='md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800'>
              Our membership options
            </h1>
            <p
              role='contentinfo'
              className='text-base hidden sm:flex leading-5 mt-5 text-gray-600'
            >
              Please select a membership option from below to get started.
            </p>
            <div className='w-56 flex flex-col space-y-6'>
              <div
                onClick={handlePriceToggleClick}
                className='bg-gray-100 shadow rounded-full flex items-center mt-10'
              >
                <button className={monthlyClassName} id='monthly'>
                  Monthly
                </button>
                <button className={annualClassName} id='annually'>
                  Annually
                </button>
              </div>
              <TrialButton />
            </div>
          </div>
          <div
            className='xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8'
            role='list'
          >
            <img
              src='https://i.ibb.co/0n6DSS3/bgimg.png'
              className='absolute w-full -ml-12 mt-24'
              alt='background circle images'
            />
            {MEMBERSHIPS.map((membership, index) => {
              return (
                <PricingComponent
                  key={index}
                  title={membership.title}
                  monthlyPrice={membership.monthlyPrice}
                  annualPrice={membership.annualPrice}
                  showAnnualPrice={showAnnualPrice}
                  dayPass={membership.dayPass}
                  monthlyLink={membership.monthlyLink}
                  annualLink={membership.annualLink}
                  monthlySubTitle={membership.monthlySubTitle}
                  annualSubTitle={membership.annualSubTitle}
                  comingSoon={membership.comingSoon}
                  joiningFee={membership.joiningFee}
                  saveAnnual={membership.saveAnnual}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
