import React from 'react';
import { useSpring, animated } from 'react-spring';

export default function AnimatedLogo({ className, sessionStorageKey }) {
  const config = {
    tension: 210,
    friction: 30,
    duration: 500,
  };
  const pathOpacity1 = useSpring({
    config,
    delay: 400,
    to: 1,
    from: {
      opacity: 0,
    },
  });

  const pathOpacity2 = useSpring({
    config,
    delay: 500,
    to: 1,
    from: {
      opacity: 0,
    },
  });

  const pathOpacity3 = useSpring({
    config,
    delay: 600,
    to: 1,
    from: {
      opacity: 0,
    },
  });

  const pathOpacity4 = useSpring({
    config,
    delay: 700,
    to: 1,
    from: {
      opacity: 0,
    },
  });
  const pathOpacity5 = useSpring({
    config,
    delay: 800,
    to: 1,
    from: {
      opacity: 0,
    },
  });
  const pathOpacity6 = useSpring({
    config,
    delay: 900,
    to: 1,
    from: {
      opacity: 0,
    },
  });
  const pathOpacity7 = useSpring({
    config,
    delay: 1000,
    to: 1,
    from: {
      opacity: 0,
    },
  });
  const pathOpacity8 = useSpring({
    config,
    delay: 1100,
    to: 1,
    from: {
      opacity: 0,
    },
  });
  const pathOpacity9 = useSpring({
    config,
    delay: 1200,
    to: 1,
    from: {
      opacity: 0,
    },
  });

  const noAnimation = useSpring({});

  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 1498 1346'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_2_2)'>
        <animated.path
          style={sessionStorageKey ? noAnimation : pathOpacity1}
          d='M741.7 19.2C735.9 29.7999 572.3 328.5 378.1 683C184 1037.5 25.2999 1327.7 25.4999 1327.9C25.6999 1328.1 74.1999 1299.1 133.2 1263.5L240.5 1198.8L528 662.2C686.1 367.1 815.5 125.2 815.5 124.6C815.5 123.6 761.6 16.8999 755.2 5.19995L752.3 -0.200073L741.7 19.2Z'
          fill='black'
        />
        <animated.path
          style={sessionStorageKey ? noAnimation : pathOpacity2}
          d='M854.4 224C854.7 236.9 855.9 304.2 857.1 373.5L859.2 499.5L886.2 550.5C901.1 578.5 913.5 601.8 913.8 602.2C914.2 602.6 941.1 588.8 973.5 571.5C1006 554.2 1032.9 539.9 1033.4 539.7C1034.1 539.5 868.3 225.6 857.3 206.5L853.8 200.5L854.4 224Z'
          fill='black'
        />
        <animated.path
          style={sessionStorageKey ? noAnimation : pathOpacity3}
          d='M620.7 652.2C499.4 899.3 400.3 1101.6 400.5 1101.8C401 1102.3 612.2 975.1 614.1 973.1C615 972.2 667.9 865.1 731.7 735L847.7 498.5L845 351.2C843.4 270.2 842 203.7 841.8 203.4C841.5 203.2 742.1 405.2 620.7 652.2Z'
          fill='black'
        />
        <animated.path
          style={sessionStorageKey ? noAnimation : pathOpacity4}
          d='M509.6 1050.4C448.1 1087.5 397.6 1118.1 397.2 1118.4C396.9 1118.7 502.6 1119 632.1 1119H867.5L902 1051.8C921 1014.9 936.7 984.3 936.8 983.8C937 983.4 866.1 983 779.3 983H621.5L509.6 1050.4Z'
          fill='black'
        />
        <animated.path
          style={sessionStorageKey ? noAnimation : pathOpacity5}
          d='M1176.8 1050.8L1142.1 1118.5L1258.7 1118.8C1322.9 1118.9 1375.5 1118.9 1375.7 1118.7C1375.9 1118.5 1359.8 1087.9 1339.9 1050.7L1303.7 983H1257.6H1211.5L1176.8 1050.8Z'
          fill='black'
        />
        <animated.path
          style={sessionStorageKey ? noAnimation : pathOpacity6}
          d='M1129.5 1202.8L1098.5 1203.1L1064.5 1269.5C1045.8 1306 1030.3 1336.7 1030.1 1337.6C1029.8 1339.2 1043 1339.3 1261.1 1338.6C1388.4 1338.2 1492.6 1337.7 1492.8 1337.6C1492.9 1337.4 1476.8 1306.8 1456.9 1269.6L1420.8 1202L1290.6 1202.2C1219.1 1202.4 1146.6 1202.6 1129.5 1202.8Z'
          fill='black'
        />
        <animated.path
          style={sessionStorageKey ? noAnimation : pathOpacity7}
          d='M144.6 1270.4C83.0999 1307.5 32.4999 1338.1 32.1999 1338.4C31.8999 1338.7 194.3 1339 393.1 1339H754.6L789 1271.9C808 1235 823.7 1204.4 823.8 1203.9C824 1203.4 709.7 1203 540.3 1203H256.5L144.6 1270.4Z'
          fill='black'
        />
      </g>
      <animated.path
        style={sessionStorageKey ? noAnimation : pathOpacity8}
        d='M931.066 1051.82L898.894 1119.56L1007 1119.86C1066.52 1119.96 1117.52 1120.06 1117.7 1119.86C1191 983.978 1191 983.978 1176.5 1014.5L1191 983.978H1005.98H963.238L931.066 1051.82Z'
        fill='#932933'
      />
      <animated.path
        style={sessionStorageKey ? noAnimation : pathOpacity9}
        d='M814.172 1269.84L782 1337.58L890.106 1337.88C949.629 1337.98 1000.62 1338.08 1000.81 1337.88C1074.11 1202 1074.11 1202 1059.61 1232.52L1074.11 1202H889.086H846.344L814.172 1269.84Z'
        fill='#932933'
      />
      <defs>
        <clipPath id='clip0_2_2'>
          <rect width='1498' height='1346' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
