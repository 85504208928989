import React from 'react';

export default function SmallLogo({ className }) {
  return (
    <svg
      width='51'
      height='46'
      viewBox='0 0 51 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_3_2)'>
        <path
          d='M25.1756 0.656165C24.9788 1.01842 19.4256 11.2266 12.8339 23.3418C6.24551 35.4569 0.858741 45.3745 0.865531 45.3813C0.872317 45.3881 2.51856 44.3971 4.52121 43.1805L8.16332 40.9692L17.922 22.6309C23.2884 12.5458 27.6806 4.27875 27.6806 4.25825C27.6806 4.22409 25.8511 0.577562 25.6339 0.177711L25.5354 -0.00683594L25.1756 0.656165Z'
          fill='black'
        />
        <path
          d='M29.001 7.65525C29.0112 8.0961 29.0519 10.3961 29.0926 12.7645L29.1639 17.0706L30.0804 18.8135C30.5861 19.7704 31.007 20.5667 31.0172 20.5804C31.0308 20.5941 31.9438 20.1224 33.0436 19.5312C34.1468 18.9399 35.0598 18.4512 35.0768 18.4444C35.1005 18.4376 29.4728 7.70995 29.0994 7.05718L28.9806 6.85214L29.001 7.65525Z'
          fill='black'
        />
        <path
          d='M21.0685 22.2891C16.9512 30.7338 13.5874 37.6476 13.5942 37.6544C13.6112 37.6715 20.7799 33.3244 20.8444 33.256C20.875 33.2252 22.6706 29.5651 24.8361 25.1189L28.7736 17.0364L28.6819 12.0024C28.6276 9.23416 28.5801 6.96151 28.5733 6.95124C28.5631 6.94439 25.1892 13.8478 21.0685 22.2891Z'
          fill='black'
        />
        <path
          d='M17.2974 35.8978C15.2099 37.1655 13.4958 38.2114 13.4822 38.2217C13.472 38.232 17.0598 38.242 21.4554 38.242H29.4457L30.6167 35.9456C31.2616 34.6845 31.7946 33.6388 31.7979 33.6217C31.8047 33.608 29.3982 33.5943 26.4519 33.5943H21.0956L17.2974 35.8978Z'
          fill='black'
        />
        <path
          d='M39.9443 35.9114L38.7664 38.2253L42.7242 38.2353C44.9034 38.2388 46.6887 38.2388 46.6954 38.2321C46.7025 38.2253 46.156 37.1794 45.4803 35.9082L44.2517 33.5944H42.687H41.1223L39.9443 35.9114Z'
          fill='black'
        />
        <path
          d='M38.3389 41.1062L37.2866 41.1161L36.1324 43.3855C35.4977 44.6328 34.9716 45.6823 34.9648 45.7129C34.9546 45.7675 35.4026 45.7711 42.8056 45.7472C47.1265 45.7333 50.6635 45.7161 50.6702 45.7129C50.6738 45.7062 50.1273 44.6603 49.4516 43.389L48.2265 41.0787L43.8069 41.0855C41.3801 41.0923 38.919 41.0994 38.3389 41.1062Z'
          fill='black'
        />
        <path
          d='M4.90814 43.4165C2.82065 44.6842 1.10312 45.7301 1.09294 45.7404C1.08276 45.7504 6.59514 45.7607 13.343 45.7607H25.6135L26.7811 43.4675C27.426 42.2066 27.9589 41.1607 27.9623 41.1436C27.9691 41.1265 24.0894 41.1129 18.3394 41.1129H8.7064L4.90814 43.4165Z'
          fill='black'
        />
        <path
          d='M31.6034 35.9463L30.5114 38.2613L34.1808 38.2716C36.2011 38.2752 37.932 38.2784 37.9383 38.2716C40.4264 33.6278 40.4264 33.6278 39.9341 34.6708L40.4264 33.6278H34.1462H32.6954L31.6034 35.9463Z'
          fill='#932933'
        />
        <path
          d='M27.6356 43.3972L26.5435 45.7122L30.213 45.7226C32.2334 45.7261 33.9643 45.7293 33.9706 45.7226C36.4586 41.0787 36.4586 41.0787 35.9663 42.1217L36.4586 41.0787H30.1784H28.7276L27.6356 43.3972Z'
          fill='#932933'
        />
      </g>
      <defs>
        <clipPath id='clip0_3_2'>
          <rect width='51' height='46' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
