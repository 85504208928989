import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import * as Pages from './pages/pagesExporter';
import LayoutWrapper from './Layout';
import PromoModal from './components/PromoModal';


function App() {
  const location = useLocation();
  const sessionStorageKey =
    window.sessionStorage.getItem('firstLoadToken') === 'true';

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!sessionStorageKey) {
        window.sessionStorage.setItem('firstLoadToken', 'true');
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <LayoutWrapper sessionStorageKey={sessionStorageKey}>
        {/* <PromoModal /> */}
        <Routes location={location}>
          <Route
            path='/'
            element={<Pages.Home sessionStorageKey={sessionStorageKey} />}
          />
          <Route path='/instructors' element={<Pages.About />} />
          <Route path='/timetable' element={<Pages.Timetable />} />
          <Route path='/classes' element={<Pages.Classes />} />
          <Route path='/location' element={<Pages.Location />} />
          <Route path='/pricing' element={<Pages.Pricing />} />
          <Route path='/faqs' element={<Pages.FAQs />} />
        </Routes>
      </LayoutWrapper>
    </>
  );
}

export default App;
