import React from 'react';

export default function Instructor({ copyOne, copyTwo, image, id, index }) {
  const flexDir = index % 2 === 0 ? 'lg:flex-row-reverse' : 'lg:flex-row';
  return (
    <div
      className={`flex flex-col items-center w-full gap-12 md:gap-6 justify-evenly ${flexDir} instructorWrapperDiv`}
      id={id}
    >
      <img
        src={image}
        alt="instructor"
        className="w-[90%] lg:w-[50%] rounded max-w-[450px] instructorPicture md:h-full px-2 sm:px-12"
      />
      <div className="flex flex-col w-full  md:w-[80%] lg:w-[40%]  min-h-[450px] copyBitBob h-full justify-evenly  gap-12 md:gap-6">
        <p className="text-lg font-medium leading-6 text-gray-600">{copyOne}</p>
        <p className="text-lg font-medium leading-6 text-gray-600">{copyTwo}</p>
      </div>
    </div>
  );
}
