import React from 'react';

/* Install pure-react-carousel using -> npm i pure-react-carousel */
export default function Quote({ text, image, name }) {
  return (
    <div>
      <div className='relative z-40 px-8 py-20 xl:px-20 2xl:mx-auto 2xl:container'>
        <div className='flex'>
          <div className='mt-14 md:flex'>
            <div className='relative lg:w-1/2 sm:w-96 xl:h-96 h-80'>
              <img
                src={image}
                alt='instructor'
                className='flex-shrink-0 object-cover w-full h-full rounded shadow-lg object-fit'
              />
              <div className='absolute top-0 right-0 items-center justify-center hidden w-32 h-32 -mr-16 bg-slate-100 rounded-full md:flex -mt-14'>
                <img
                  src='https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg'
                  alt='commas'
                />
              </div>
            </div>
            <div className='flex flex-col justify-center mt-4 space-y-2 md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0'>
              <div>
                <p className='mt-4 text-lg font-medium leading-6 text-gray-600'>
                  {text}
                </p>
              </div>
              <div className='mt-8 md:mt-0'>
                <p className='text-base font-medium leading-4 text-gray-800'>
                  {name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
