import React from 'react';
import ClassIconWrapper from '../../components/ClassIconWrapper';
export default function NoGi() {
  return (
    <ClassIconWrapper>
      <svg
        width='157'
        height='142'
        viewBox='0 0 157 142'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M142.419 28.2258C138.056 17.875 129.487 9.81832 118.914 6.12089L101.716 0.108069C100.401 -0.346989 98.9113 0.696373 98.9113 2.08932C98.9113 6.15734 89.9464 9.34643 78.5024 9.34643C67.0583 9.34643 58.0934 6.15916 58.0934 2.08932C58.0934 0.696417 56.6041 -0.346989 55.2889 0.108069L38.0911 6.12089C27.5177 9.8169 18.9508 17.875 14.5844 28.2258L0.165282 62.424C-0.0639732 62.9657 -0.0536299 63.5799 0.189414 64.1146C0.43246 64.6492 0.890956 65.0576 1.44776 65.2376L30.7184 74.706V133.35C30.7184 138.119 34.583 142 39.3352 142H117.661C122.412 142 126.278 138.121 126.278 133.35L126.282 74.706L155.552 65.2376C156.111 65.0576 156.568 64.6493 156.811 64.1146C157.054 63.5782 157.064 62.9656 156.835 62.424L142.419 28.2258ZM62.314 11.0728C66.6198 12.663 72.3702 13.5403 78.5034 13.5403C86.4552 13.5403 94.4445 12.0418 99.0708 8.82488C97.0713 18.3832 88.6113 25.5832 78.5034 25.5832C68.4146 25.5832 59.9681 18.4093 57.948 8.87671C59.0822 9.66226 60.5129 10.4063 62.3142 11.0725L62.314 11.0728ZM4.90855 61.9474L7.98021 54.6593L30.7231 61.4681V70.2982L4.90855 61.9474ZM122.102 133.349C122.102 135.804 120.113 137.803 117.665 137.803H39.339C36.8913 137.803 34.9002 135.805 34.9002 133.349L34.9019 45.8146C34.9019 44.6553 33.966 43.7174 32.8128 43.7174C31.6579 43.7174 30.7236 44.657 30.7236 45.8146V57.0876L9.62008 50.7701L18.4368 29.8572C22.341 20.5966 30.0083 13.3877 39.4697 10.0797L53.3425 5.22964C53.7424 18.83 64.8619 29.7779 78.5053 29.7779C92.1486 29.7779 103.268 18.83 103.668 5.22964L117.541 10.0797C127.002 13.3881 134.668 20.5966 138.572 29.8572L147.413 50.8272L126.285 57.1514V45.8141C126.285 44.6548 125.349 43.717 124.196 43.717C123.041 43.717 122.107 44.6565 122.107 45.8141L122.102 133.349ZM126.282 70.2973V61.5315L149.049 54.7155L152.099 61.9465L126.282 70.2973Z'
          fill='black'
        />
      </svg>
    </ClassIconWrapper>
  );
}
