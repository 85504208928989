import React from 'react';

export default function FindUs() {
  const DIRECTIONS = {
    ONE: {
      title: 'Enter through the alley',
      subTitle: 'Walk down towards the studios',
    },
    TWO: {
      title: 'Pass through the gate',
      subTitle: `and head to the main entrance on the left. To access the academy, press 9 and ${String.fromCharCode(
        9166
      )}`,
    },
    THREE: {
      title: 'Enter the building',
      subTitle: 'and go down the corridor until you find Unit 5',
    },
  };
  return (
    <div className='overflow-y-hidden bg-gray-100'>
      <div className='mx-auto container f-f-p px-4 xl:px-0 py-24'>
        <h1 className='focus:outline-none text-center text-3xl lg:text-4xl font-extrabold lg:leading-9 tracking-wider text-gray-900'>
          How to find us{' '}
        </h1>
        <div className='md:mt-24 f-f-p'>
          <div className='hidden md:flex justify-center w-full'>
            <div className='flex flex-col items-center md:items-end md:pr-12 md:border-r-4 border-gray-300 relative md:w-1/2'>
              <div aria-label='sign up' role='img'>
                <img
                  className='focus:outline-none w-2/3 rounded-xl mt-10'
                  src='images/alley.png'
                  alt='how it work'
                />
              </div>
              <div aria-label='2' role='img'>
                <img
                  className='focus:outline-none mt-24 '
                  src='https://tuk-cdn.s3.amazonaws.com/can-uploader/3_step_center_aligned_how_it_worksSvg2.svg'
                  alt='How it works'
                />
              </div>
              <div className='flex mt-12 flex-col items-center lg:items-end md:w-8/12'>
                <h1 className='focus:outline-none text-xl font-bold leading-5'>
                  {DIRECTIONS.TWO.title}
                </h1>
                <h2 className='focus:outline-none text-gray-500 mt-3 pl-3 text-center md:text-right text-base leading-6 tracking-wide'>
                  {DIRECTIONS.TWO.subTitle}
                </h2>
              </div>
              <div aria-label='transactions' role='img'>
                <img
                  className='focus:outline-none mt-24 w-2/3 rounded-xl'
                  src='images/building.png'
                  alt='find us'
                />
              </div>
              <img
                className='hidden md:block absolute right-0 top-0 -mt-2 -mr-1'
                src='https://cdn.tuk.dev/assets/components/111220/Fs7/line.png'
                alt='find us'
              />
            </div>
            <div className='flex flex-col items-center md:items-start md:pl-12 lg:border-gray-400 mt-20 md:mt-0 md:w-1/2'>
              <div aria-label={1} role='img'>
                <img
                  src='https://tuk-cdn.s3.amazonaws.com/can-uploader/3_step_center_aligned_how_it_worksSvg4.svg'
                  alt='find us'
                />
              </div>
              <div className='flex mt-6 flex-col items-center md:items-start md:w-8/12'>
                <h1 className='focus:outline-none text-xl font-bold leading-5'>
                  {DIRECTIONS.ONE.title}
                </h1>
                <h2 className='focus:outline-none text-gray-500 mt-3 text-base leading-6 tracking-wide'>
                  {DIRECTIONS.ONE.subTitle}
                </h2>
              </div>
              <div aria-label='wallet' role='img'>
                <img
                  className='focus:outline-none mt-32 w-2/3 rounded-xl'
                  src='images/gate.png'
                  alt='find us'
                />
              </div>
              <div aria-label={3} role='img'>
                <img
                  className='focus:outline-none mt-20'
                  src='https://tuk-cdn.s3.amazonaws.com/can-uploader/3_step_center_aligned_how_it_worksSvg6.svg'
                  alt='find us'
                />
              </div>
              <div className='flex mt-6 flex-col items-center md:items-start md:w-8/12'>
                <h1 className='focus:outline-none text-xl font-bold leading-5'>
                  {DIRECTIONS.THREE.title}
                </h1>
                <h2 className='focus:outline-none text-gray-500 mt-3 text-base leading-6 tracking-wide'>
                  {DIRECTIONS.THREE.subTitle}
                </h2>
              </div>
            </div>
          </div>
          <div className='md:hidden flex flex-col items-center w-full'>
            <img
              className='focus:outline-none my-10 rounded-xl'
              src='images/alley.png'
              alt='how it work'
            />
            <img
              src='https://tuk-cdn.s3.amazonaws.com/can-uploader/3_step_center_aligned_how_it_worksSvg4.svg'
              alt='find us'
            />
            <div className='mt-10'>
              <h1 className='text-xl text-center tracking-wide leading-5 font-bold'>
                {DIRECTIONS.ONE.title}
              </h1>
              <h2 className='text-gray-500 mt-3 text-center text-base leading-6 tracking-wide'>
                {DIRECTIONS.ONE.subTitle}
              </h2>
            </div>
            <img
              className='focus:outline-none my-10 rounded-xl'
              src='images/gate.png'
              alt='find us'
            />
            <img
              src='https://tuk-cdn.s3.amazonaws.com/can-uploader/3_step_center_aligned_how_it_worksSvg2.svg'
              alt='find us'
            />
            <div className='mt-10'>
              <h1 className='text-xl tracking-wide text-center leading-5 font-bold'>
                {DIRECTIONS.TWO.title}
              </h1>
              <h2 className='text-gray-500 mt-3 pl-3 text-center text-base leading-6 tracking-wide'>
                {DIRECTIONS.TWO.subTitle}
              </h2>
            </div>
            <img
              className='focus:outline-none my-10 rounded-xl'
              src='images/building.png'
              alt='find us'
            />
            <img
              className
              src='https://tuk-cdn.s3.amazonaws.com/can-uploader/3_step_center_aligned_how_it_worksSvg6.svg'
              alt='find us'
            />
            <div className='flex mt-10 flex-col items-center md:items-start md:w-8/12'>
              <h1 className='text-xl text-center tracking-wide leading-5 font-bold'>
                {DIRECTIONS.THREE.title}
              </h1>
              <h2 className='text-gray-500 mt-3 text-center text-base leading-6 tracking-wide'>
                {DIRECTIONS.THREE.subTitle}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
