import React from 'react';
import { NavLink } from 'react-router-dom';

export default function DesktopLink({ title, path, className }) {
  return (
    <>
      <NavLink
        className={`${className} flex items-center h-auto mx-2 lg:mx-3 text-lg font-b tracking-normal text-gray-800 hover:bg-slate-100 rounded-lg px-2 py-1 cursor-pointer`}
        to={path}
      >
        {title}
      </NavLink>
    </>
  );
}
