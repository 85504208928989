import React from 'react';

export default function Form({ formRef, submitForm, closeModal }) {
  return (
    <form className='mt-2' ref={formRef} onSubmit={submitForm}>
      <div className='flex items-center space-x-9'>
        <input
          placeholder='Full Name'
          name='from_name'
          className='w-full focus:outline-none placeholder-gray-500 py-3 px-3 text-sm leading-none text-gray-800 bg-white border rounded border-gray-200'
        />
      </div>
      <div className='flex items-center space-x-9 mt-8'>
        <input
          placeholder='Email'
          type='email'
          name='from_email'
          className='w-full focus:outline-none placeholder-gray-500 py-3 px-3 text-sm leading-none text-gray-800 bg-white border rounded border-gray-200'
        />
      </div>
      <div className='mt-8'>
        <textarea
          placeholder='Description'
          name='message'
          className='py-3 pl-3 overflow-y-auto h-24 border rounded border-gray-200 w-full resize-none focus:outline-none'
          defaultValue={''}
        />
      </div>
      <div className='flex items-center justify-between mt-9'>
        <button
          onClick={closeModal}
          className='px-6 py-3 bg-gray-400 hover:bg-gray-500 shadow rounded text-sm text-white'
        >
          Cancel
        </button>
        <button
          type='submit'
          className='px-6 py-3 bg-slate-700 hover:bg-opacity-80 shadow rounded text-sm text-white'
        >
          Send
        </button>
      </div>
    </form>
  );
}
