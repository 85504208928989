import React, { useState } from 'react';
import SmallLogo from '../../svgs/SmallLogo';
import NavLogo from '../../svgs/NavLogo';
import DesktopLink from './DesktopLink';
import MobileLink from './MobileLink';
import { useSpring, animated } from 'react-spring';
import { NavLink } from 'react-router-dom';
import TrialButton from '../TrialButton';
import ContactButton from '../Contact/ContactButton';

export default function Navbar({ sessionStorageKey, handleModalOpen }) {
  const [show, setShow] = useState(false);

  const configNav = {
    tension: 210,
    friction: 30,
    duration: 900,
  };
  const mainFadeIn = useSpring({
    configNav,
    delay: 1700,
    to: `translateY(${0})`,
    from: {
      transform: `translateY(${-500}px)`,
    },
  });

  const mobileNav = useSpring({
    configNav,
    transform: show ? `translateX(${0}vw)` : `translateX(${-100}vw)`,
  });

  const noAnimation = useSpring({});
  return (
    <animated.div
      style={sessionStorageKey ? noAnimation : mainFadeIn}
      className="fixed z-50 w-full bg-white opacity-90"
    >
      <animated.div
        className="absolute flex w-full h-full mobNavWrapper lg:hidden"
        style={mobileNav}
      >
        <div
          className="fixed inset-0 w-full h-full opacity-50"
          onClick={() => setShow(!show)}
        />
        <div
          className="absolute top-0 left-0 flex-col justify-between w-full h-full transition duration-150 ease-in-out bg-white shadow"
          onClick={() => setShow(!show)}
        >
          <div className="flex flex-col justify-between h-full bg-white">
            <div className="px-6 pt-4 bg-white">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <SmallLogo className="mt-4 w-4/4" />
                </div>
                <div
                  id="cross"
                  className="text-gray-800 "
                  onClick={() => setShow(!show)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-x"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1={18} y1={6} x2={6} y2={18} />
                    <line x1={6} y1={6} x2={18} y2={18} />
                  </svg>
                </div>
              </div>
              <ul className="f-m-m">
                <MobileLink title="Home" path="/" logo="fa-house" />
                <MobileLink
                  title="Instructors"
                  path="/instructors"
                  logo="fa-user-ninja"
                />
                <MobileLink
                  title="Timetable"
                  path="/timetable"
                  logo="fa-table-list"
                />
                <MobileLink title="Classes" path="/classes" logo="fa-list" />
                <MobileLink
                  title="Location"
                  path="/location"
                  logo="fa-location-dot"
                />
                <MobileLink
                  title="Pricing"
                  path="/pricing"
                  logo="fa-dollar-sign"
                />
                <MobileLink title="FAQs" path="/faqs" logo="fa-question" />
                <a href="https://shop.lucianocristovam.com">
                  <li className="pt-8 text-gray-800">
                    <div className="flex items-center">
                      <i className={`fa-solid fa-store`}></i>

                      <p className="ml-3 text-xl text-gray-800 font-b ">
                        Store
                      </p>
                    </div>
                  </li>
                </a>
                <li className="my-6">
                  <TrialButton className="bg-red-site" />
                </li>
                <li className="my-6">
                  <ContactButton
                    className="bg-gray-200"
                    handleModalOpen={handleModalOpen}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </animated.div>
      {/* Mobile */}
      <nav className="w-full mx-auto bg-white shadow navMain">
        <div className="container flex items-center justify-between h-12 px-6 mx-auto md:justify-center ">
          <div className="flex items-center justify-between w-full h-full">
            <div className="relative w-[50px] h-12">
              <div className="flex items-end justify-center shadow-md absolute bottom-[-50px] bg-white h-24 w-auto  rounded-xl  px-2">
                <div className="relative">
                  <div className="navLogoBox"></div>
                  <NavLink to="/">
                    <NavLogo className="w-[80px] navLogo" />
                  </NavLink>
                </div>
              </div>
            </div>
            <ul className="items-center hidden h-full lg:flex">
              <DesktopLink title="Home" path="/" className="hidden lg:flex" />
              <DesktopLink title="Instructors" path="/instructors" />
              <DesktopLink title="Timetable" path="/timetable" />
              <DesktopLink title="Classes" path="/classes" />
              <DesktopLink title="Location" path="/location" />
              <DesktopLink title="Pricing" path="/pricing" />
              <DesktopLink title="FAQs" path="/faqs" />
              <a
                className={`flex items-center h-auto mx-2 lg:mx-3 text-lg font-b tracking-normal text-gray-800 hover:bg-slate-100 rounded-lg px-2 py-1 cursor-pointer`}
                href="https://shop.lucianocristovam.com"
              >
                Store
              </a>
              <TrialButton className="desktopNavTrialButton bg-red-site" />
            </ul>
          </div>
          <div className="relative flex items-center visible lg:hidden">
            <svg
              onClick={() => setShow(!show)}
              aria-label="Main Menu"
              aria-haspopup="true"
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-menu"
              width={32}
              height={32}
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#2c3e50"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1={4} y1={8} x2={20} y2={8} />
              <line x1={4} y1={16} x2={20} y2={16} />
            </svg>
          </div>
        </div>
      </nav>
      {/* Navigation ends */}
    </animated.div>
  );
}
