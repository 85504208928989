import React from 'react';
import Gi from '../svgs/Classes/Gi';
import NoGi from '../svgs/Classes/NoGi';
import Kids from '../svgs/Classes/Kids';
import Boxfit from '../svgs/Classes/Boxfit';
import { useSpring, animated } from 'react-spring';

const Classes = ({ style }) => (
  <animated.div style={style} className='overflow-y-hidden'>
    <div className='px-4 py-12 xl:mx-auto xl:container xl:px-20 md:px-6'>
      <div className='items-center justify-center lg:flex lg:space-x-12 2xl:space-x-6'>
        <div className>
          <p className='text-3xl font-extrabold leading-9 text-gray-800 lg:text-4xl'>
            Classes
          </p>
          <p className='w-full mt-4 text-lg leading-7 text-gray-600 xl:w-7/12'>
            Brazilian Jiu Jitsu for everyone
          </p>
          <div className='w-full mt-6 lg:hidden lg:w-3/5 xl:w-3/5 lg:mt-0'>
            <img
              src='images/adult_class_1.jpeg'
              alt='ongoing meeting'
              className='object-center w-full h-full rounded-md object-fit'
            />
          </div>
          <div className='grid gap-6 mt-6 md:mt-10 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 lg:mt-6 2xl:mt-12'>
            <div className='flex items-center'>
              <Gi />
              <div className='flex flex-col items-start pt-8 ml-6'>
                <h2 className='text-lg font-semibold leading-4 text-gray-800'>
                  Gi
                </h2>
                <p className='mt-2 text-base leading-6 text-gray-600 lg:w-40 2xl:w-52'>
                  Traditional Jiu Jitsu with the gi (kimono)
                </p>
              </div>
            </div>
            <div className='flex items-center'>
              <NoGi />
              <div className='flex flex-col items-start pt-8 ml-6'>
                <h2 className='text-lg font-semibold leading-4 text-gray-800'>
                  NoGi
                </h2>
                <p className='mt-2 text-base leading-6 text-gray-600 lg:w-40 2xl:w-52'>
                  Jiu Jitsu without the gi, more like MMA
                </p>
              </div>
            </div>

            <div className='flex items-center'>
              <Kids />
              <div className='flex flex-col items-start pt-8 ml-6'>
                <h2 className='text-lg font-semibold leading-4 text-gray-800'>
                  Kids
                </h2>
                <p className='mt-2 text-base leading-6 text-gray-600 lg:w-40 2xl:w-52'>
                  Kids classes for all ages
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden w-full mt-6 lg:block lg:w-2/5 xl:w-2/5 lg:mt-0'>
          <img
            src='images/adult_class_1.jpeg'
            alt='ongoing meeting'
            className='object-center w-full h-full rounded-md object-fit'
          />
        </div>
      </div>
    </div>
  </animated.div>
);
export default Classes;
