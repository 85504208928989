import React from 'react';
import { ADDRESS } from '../../data/siteConstants';

export default function BusinessAddress() {
  return (
    <div className='text-white flex flex-col lg:items-end'>
      <div>
        <h2 className='text-2xl font-semibold'>Address</h2>
        <ul className='mt-2 text-lg'>
          <li>{ADDRESS.TITLE}</li>
          <li>{ADDRESS.LINE_ZERO}</li>

          <li>{ADDRESS.LINE_ONE}</li>
          <li>{ADDRESS.LINE_TWO}</li>
          <li>{ADDRESS.POSTCODE}</li>
          <li>{ADDRESS.COUNTRY}</li>
        </ul>
      </div>
    </div>
  );
}
