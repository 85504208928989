import React from 'react';
import ClassIconWrapper from '../../components/ClassIconWrapper';
export default function Gi() {
  return (
    <ClassIconWrapper>
      <svg
        width='341'
        height='154'
        viewBox='0 0 341 154'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        stroke='black'
      >
        <path
          d='M215.624 86.3756C216.706 82.3508 217.247 78.0959 217.454 74.1999C217.648 74.2944 217.843 74.3893 218.038 74.4844C228.984 79.8245 241.824 86.0882 255.999 92.7185L228.31 124.514C218.73 120.196 210.122 115.892 202.487 111.88C208.293 105.499 213.083 96.6556 215.624 86.3756Z'
          strokeWidth='5'
        />
        <path
          d='M293.561 112.664L273.795 142.678C268.413 140.298 262.959 137.927 257.513 135.559C249.338 132.006 241.182 128.46 233.317 124.904L257.853 97.0737C261.737 98.7688 265.646 100.461 269.551 102.152C277.651 105.659 285.736 109.159 293.561 112.664Z'
          fill='black'
          strokeWidth='5'
        />
        <path
          d='M311.024 119.281L291.838 150.923C286.706 148.971 281.571 147.002 276.214 144.819L297.407 113.845C299.79 114.772 302.067 115.695 304.298 116.598C304.528 116.692 304.758 116.785 304.988 116.878C307.025 117.703 309.028 118.511 311.024 119.281Z'
          strokeWidth='5'
        />
        <path
          d='M211.906 66.2129L211.93 66.2514C214.802 71.0442 215.184 79.3954 212.825 88.0633C210.482 96.6734 205.635 104.82 199.034 109.179C196.293 110.978 190.821 112.681 184.104 114.093C180.071 114.94 175.735 115.656 171.534 116.225C182.02 105.834 188.407 93.9034 188.821 81.7048L188.821 81.7023C189.192 70.4615 184.751 60.2464 176.623 53.0556C179.592 54.4737 183.088 55.87 186.824 57.2437L186.824 57.2438C191.486 58.9577 196.137 60.2395 199.854 61.0916L199.907 61.1037L199.96 61.1135C203.369 61.7406 205.974 62.33 207.978 63.1585C209.919 63.9612 211.125 64.9207 211.906 66.2129ZM186.325 81.62L186.325 81.62L186.325 81.62Z'
          strokeWidth='5'
        />
        <path
          d='M161.047 80.4025C153.607 84.7003 141.1 91.9132 125.465 100.204C122.193 90.3001 121.91 77.3603 129.381 61.2599C139.801 66.4762 151.08 73.6336 161.047 80.4025Z'
          strokeWidth='5'
        />
        <path
          d='M161.777 77.4088C147.918 68.5247 136.354 62.2739 126.607 58.4823C145.032 48.0807 162.219 46.1569 174.659 52.8886C183.859 58.2688 189.203 67.8185 188.836 78.5487C188.495 83.2573 187.29 88.2489 184.981 93.0268C183.637 92.1162 182.051 91.0163 180.251 89.7675C179.263 89.0821 178.21 88.3519 177.097 87.5834C172.705 84.5514 167.455 80.9821 161.777 77.4088Z'
          strokeWidth='5'
        />
        <path
          d='M3.73968 118.229C22.6111 112.227 45.9479 102.46 73.7288 88.9668C100.442 76.1926 122.554 64.1037 128.412 60.6768C128.568 60.742 128.732 60.8114 128.904 60.8847C123.771 71.6781 120.037 85.9658 125.415 100.538C93.7204 116.333 51.8382 136.011 20.3394 146.738L3.73968 118.229Z'
          strokeWidth='5'
        />
        <path
          d='M182.733 96.1733C177.945 103.6 171.928 110.582 165.033 117.118H161.415C156.077 116.569 149.857 114.137 142.725 111.348C142.034 111.078 141.334 110.804 140.625 110.528C140.625 110.528 140.625 110.528 140.625 110.528L140.564 110.504C136.036 108.742 131.445 106.955 126.829 105.392C133.656 101.311 139.917 97.5617 145.406 94.275C154.813 88.6427 161.951 84.3685 165.78 82.1073C172.928 88.0026 178.957 93.0251 182.733 96.1733Z'
          strokeWidth='5'
        />
        <path
          d='M32.8807 3.92103C54.3551 29.759 87.3624 49.8682 126.714 59.9672C116.505 65.5314 100.271 74.2549 81.7784 83.0337C49.9883 69.349 22.9086 49.5658 3.46249 25.9539C12.7229 17.9647 22.6237 10.6189 32.8807 3.92103Z'
          strokeWidth='5'
        />
        <path
          d='M214.865 70.9341C253.784 60.3919 286.523 40.2359 308.164 14.4283C318.68 20.7691 328.273 28.0873 337.537 36.1116C317.7 60.1682 290.969 80.3494 258.806 94.0363L258.339 93.8172C257.391 93.3728 256.024 92.7318 254.348 91.9457C250.995 90.3735 246.402 88.2207 241.444 85.8982C232.164 81.551 221.599 76.6074 215.489 73.7656C215.314 72.8 215.116 71.8487 214.865 70.9341Z'
          strokeWidth='5'
        />
      </svg>
    </ClassIconWrapper>
  );
}
