import React from 'react';

export default function Medal({ className }) {
  return (
    <div className='relative w-16 h-16 '>
      <div className='z-20 flex items-center justify-center flex-shrink-0 w-16 h-16 mr-3'>
        <svg
          width='752pt'
          height='752pt'
          version='1.1'
          viewBox='0 0 752 752'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g>
            <path d='m325.07 301.6c-1.7891 0-3.4727-0.94531-4.5234-2.5273l-70.512-115.03c-0.73828-1.2617-0.94531-2.6328-0.63281-4 0.31641-1.3672 1.2617-2.5273 2.4219-3.2617l56.621-32.625c2.5273-1.4727 5.6836-0.63281 7.1562 1.8945l85.246 147.65c1.4727 2.5273 0.63281 5.6836-1.8945 7.1562-2.5273 1.4727-5.7891 0.63281-7.1562-1.8945l-82.613-143.12-47.359 27.363 67.773 110.4c1.4727 2.5273 0.73828 5.6836-1.6836 7.2617-0.94922 0.52344-1.8984 0.73438-2.8438 0.73438z' />
            <path d='m439.88 286.34c-0.94531 0-1.7891-0.21094-2.6328-0.73828-2.5273-1.4727-3.3672-4.6289-1.8945-7.1562l54.934-95.137-47.465-27.363-62.301 107.87c-1.4727 2.5273-4.6289 3.3672-7.1562 1.8945-2.5273-1.4727-3.3672-4.6289-1.8945-7.1562l64.832-112.5c1.4727-2.5273 4.6289-3.3672 7.1562-1.8945l56.621 32.73c2.5273 1.4727 3.3672 4.6289 1.8945 7.1562l-57.566 99.77c-0.94922 1.5781-2.6328 2.5234-4.5273 2.5234z' />
            <path d='m376 579.64c-69.039 0-125.23-56.199-125.23-125.23 0-69.039 56.199-125.23 125.23-125.23 69.039 0 125.23 56.199 125.23 125.23 0.003906 69.035-56.195 125.23-125.23 125.23zm0-239.95c-63.25 0-114.71 51.461-114.71 114.71s51.461 114.71 114.71 114.71 114.71-51.461 114.71-114.71c0.003906-63.25-51.461-114.71-114.71-114.71z' />
            <path d='m376 608.58c-85.035 0-154.18-69.145-154.18-154.18 0-56.305 30.309-107.66 79.457-134.81v-38.52c0-2.9453 2.3164-5.2617 5.2617-5.2617h9.0508c2.9453 0 5.2617 2.3164 5.2617 5.2617 0 2.9453-2.3164 5.2617-5.2617 5.2617h-3.7891v36.309c0 2-1.0508 3.7891-2.8398 4.6289-47.254 24.941-76.613 73.668-76.613 127.02-0.003906 79.355 64.402 143.76 143.65 143.76s143.65-64.406 143.65-143.65c0-53.355-29.363-102.08-76.613-127.13-1.6836-0.94531-2.8398-2.7344-2.8398-4.6289v-36.309l-52.621-0.003906c-2.9453 0-5.2617-2.3164-5.2617-5.2617 0-2.9453 2.3164-5.2617 5.2617-5.2617h57.883c2.9453 0 5.2617 2.3164 5.2617 5.2617v38.414c49.043 27.258 79.457 78.613 79.457 134.81 0 85.141-69.145 154.28-154.18 154.28z' />
            <path d='m426.94 323.17h-101.87c-2.9453 0-5.2617-2.3164-5.2617-5.2617v-21.469c0-2.9453 2.3164-5.2617 5.2617-5.2617h101.87c2.9453 0 5.2617 2.3164 5.2617 5.2617v21.469c0 2.9453-2.3164 5.2617-5.2617 5.2617zm-96.609-10.523h91.348v-10.945h-91.348z' />
            <path d='m376 546.7c-50.938 0-92.297-41.465-92.297-92.297 0-50.832 41.465-92.297 92.297-92.297s92.297 41.465 92.297 92.297c0 50.836-41.359 92.297-92.297 92.297zm0-174.07c-45.148 0-81.773 36.73-81.773 81.773s36.73 81.773 81.773 81.773 81.773-36.73 81.773-81.773-36.625-81.773-81.773-81.773z' />
            <path d='m343.59 509.55c-1.0508 0-2.2109-0.31641-3.0508-1.0508-1.3672-1.0508-2.2109-2.6328-2.2109-4.4219l0.94531-32.52-19.785-25.785c-1.0508-1.3672-1.3672-3.1562-0.84375-4.8398 0.52734-1.6836 1.8945-2.9453 3.4727-3.4727l31.152-9.1562 18.418-26.836c0.94531-1.4727 2.6328-2.3164 4.3164-2.3164s3.3672 0.84375 4.3164 2.3164l18.418 26.836 31.152 9.1562c1.6836 0.52734 2.9453 1.7891 3.4727 3.4727s0.21094 3.4727-0.84375 4.8398l-19.68 25.891 0.94531 32.52c0 1.6836-0.73828 3.3672-2.2109 4.4219-1.4727 1.0508-3.2617 1.2617-4.8398 0.73828l-30.73-11.055-30.625 10.945c-0.52344 0.21094-1.1562 0.31641-1.7891 0.31641zm-11.152-64.09 16.312 21.152c0.73828 0.94531 1.1562 2.1055 1.0508 3.3672l-0.73828 26.73 25.152-8.9453c1.1562-0.42188 2.4219-0.42188 3.5781 0l25.152 8.9453-0.73828-26.73c0-1.1562 0.31641-2.4219 1.0508-3.3672l16.312-21.152-25.574-7.5781c-1.1562-0.31641-2.2109-1.0508-2.8398-2.1055l-15.152-21.891-15.156 21.996c-0.63281 1.0508-1.6836 1.6836-2.8398 2.1055z' />
          </g>
        </svg>
      </div>
    </div>
  );
}
