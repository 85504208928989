import { MapContainer, TileLayer } from 'react-leaflet';
import { Circle, Marker, Polyline, Popup, useMapEvents } from 'react-leaflet';
import { ADDRESS } from '../data/siteConstants';
import logo from './logo.svg';
import L from 'leaflet';
export default function Map() {
  const icon = new L.Icon({
    iconUrl: 'favicon.svg',
    iconSize: [40, 40],
    className: 'icon-bg bg-white w-[50px] h-[50px] rounded-lg shadow-lg',
  });
  return (
    <div className=" w-full h-[500px] mb-12">
      <MapContainer
        center={{ lat: 51.549, lng: -0.143 }}
        zoom={14}
        scrollWheelZoom={true}
        minZoom={7}
        maxZoom={16}
        zoomControl={false}
        className="map-container"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker
          name={'Your position'}
          position={{ lng: -0.143461, lat: 51.549678 }}
          icon={icon}
        >
          <Popup>
            <h4>{ADDRESS.TITLE}</h4>
            <p>{ADDRESS.LINE_ONE}</p>
            <p>{ADDRESS.LINE_TWO}</p>
            <p>{ADDRESS.POSTCODE}</p>
            <p>{ADDRESS.COUNTRY}</p>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}
