import React from 'react';

export default function ClassIconWrapper({ children }) {
  return (
    <div className='relative w-16 h-16 '>
      <div className='z-20 flex items-center justify-center flex-shrink-0 w-16 h-16 p-2 mt-2 mr-3 bg-gray-200 rounded-md'>
        {children}
      </div>
    </div>
  );
}
