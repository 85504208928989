import React from 'react';

export default function Success() {
  return (
    <div className='px-4 md:px-10 pt-6 md:pt-12 md:pb-4 pb-7 flex flex-col justify-center min-h-[300px] items-center'>
      <h1 className='text-2xl font-b'>Message Sent Successfully</h1>
      <h5 className='font-r'>We will get back to you as soon as possible.</h5>
    </div>
  );
}
