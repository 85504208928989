export const faqData = [
  {
    question: 'How many days a week should I train?',
    answer:
      'We suggest as many as you’re comfortable with! The more classes the quicker you’ll get better! But we’d suggest at least two classes a week.',
  },
  {
    question:
      'What should I bring with me to my first class and what should I wear?',
    answer:
      'Best to wear loose clothing like shorts or leggings  bottoms and a t shirt. Also bring some water with you. Once you have joined our academy and training regularly you are required to wear a kimono which can be purchased from our shop. No Gi class please purchase the No GI gear( Shorts and Rashguard)',
  },
  {
    question: 'Is there area I can change in?',
    answer:
      'Yes, there’s changing room available, shower and toilets separate. ',
  },
  {
    question: 'Do I have to pay for promotions?',
    answer:
      'NEVER! We believe stripe and belt promotions are earned through hard work and dedication!',
  },
  {
    question: 'Is the Academy more focused on competition or recreational BJJ?',
    answer:
      'Yes! Professor Luciano Cristovam have a passion for competition and have been able to obtain medals at the most prestigious BJJ competitions, but they still realize the most important part of BJJ is to have fun! We embrace those that compete and support them when they are getting ready for a competition, but we do not expect all our students to be competitors.',
  },
  {
    question: 'How long is a typical adult’s class?',
    answer:
      'A typical adult’s class is 1 hour long. However, we have an open-mat after the class of 30 minutes and competition class is an hour and half long.',
  },
  {
    question: 'When can I start sparring or live training?',
    answer:
      'Specific Sparring   you can do it straight away, Full sparring starting from standing or on you knees is for white belts with three stripes and up. But if you have prior grappling, judo, or wrestling knowledge exceptions can be made!',
  },
  {
    question:
      'What is the difference between gi and no gi Brazilian Jiu Jitsu?',
    answer:
      'Brazilian Jiu Jitsu is tradition done in the gi, also known as a kimono, and is usually made out of 100% cotton and is tied together by a belt and is colored according to the individuals’ rank. No gi is when the kimono is not worn during practice, and instead, a pair of zipper-less and buttonless board shorts are worn along with a tight-fitting sport shirt called a rash guard.',
  },
  {
    question: 'Where are you located?',
    answer:
      'Luciano Cristovam is located in Kentish Town, London. 45 Holmes Road, Unit 5, NW5 3AN.',
  },
  {
    question: 'Is parking available?',
    answer:
      'Yes, there’s free parking in the street close by and pay on display.',
  },
  {
    question: 'What if I only want to train no gi?',
    answer:
      'Although we suggest mixing both gi and no gi training to become more well rounded in Brazilian Jiu Jitsu, you can still attend no gi training 6 days a week during our live training sessions and our no gi classes.',
  },
  {
    question:
      'Is Brazilian Jiu Jitsu a safe and effective way to get fit and get in shape?',
    answer:
      'YES! Under proper supervision and guidance Brazilian Jiu Jitsu is a great, unique, and effective way to become fit!',
  },
  {
    question:
      'Is Brazilian Jiu Jitsu useful in a self-defense situation on the streets?',
    answer:
      'Yes! Both gi and no gi have great applications in real world scenarios! Not only can Brazilian Jiu Jitsu help you physically respond to a self-defense situation, it can also help you mentally prepare too!',
  },
  {
    question: 'Am I too old to train?',
    answer:
      'We believe that Jiu-Jitsu is for everyone. And Professor Luciano Cristovam is very professional and knows how to make Jiu-Jitsu accessible. If you want to train, we’ll make sure you are able to in the safest manner possible!',
  },
  {
    question: 'Do I need to be in shape to train Brazilian Jiu Jitsu?',
    answer:
      'Nope! One of the best parts about Brazilian Jiu Jitsu is the customizable aspect to training. Coaches and Professors will make sure you train and workout to break through walls and achieve personal goals without pushing yourself too hard.',
  },
  {
    question: 'How long does it take to get to black belt?',
    answer:
      'This is always a hard question to answer because it is very much dependent on the individual, and how much they train, their personal growth while training, and other important factors. But a good rule of thumb is if you attend 2-3 classes a week, you could see a black belt around your waist in about 10 years. Of course, the more classes you attend, and the more consistent you are in your long term training the quicker the journey is to black belt!',
  },
];
