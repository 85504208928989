import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CardWrapper = ({ children }) => {
  return (
    <div className='w-full sm:w-1/3 h-64 flex flex-col  items-center justify-center'>
      {children}
    </div>
  );
};

export const Text = ({ children }) => {
  return (
    <span className='text-left text-base  leading-none text-gray-100'>
      {children}
    </span>
  );
};

export const Title = ({ children }) => {
  return (
    <h2 className='text-xl  mb-4 xl:text-xl font-bold xl:font-semibold leading-4 xl:leading-5 text-white'>
      {children}
    </h2>
  );
};

export const Copyright = ({ children }) => {
  return (
    <p className='mt-10 md:mt-12 xl:mt-0 text-sm leading-none text-white'>
      {children}
    </p>
  );
};

const IconWrapper = ({ children }) => {
  return <div className='flex space-x-2 whitespace-nowrap'>{children}</div>;
};

export const Phone = ({ children }) => {
  return (
    <IconWrapper>
      <i className='fa-solid fa-mobile-button text-white'></i>
      <span className='text-left text-base  leading-none text-gray-100'>
        {children}
      </span>
    </IconWrapper>
  );
};

export const Email = ({ children }) => {
  return (
    <IconWrapper>
      <i className='fa-solid fa-envelope text-white'></i>
      <span className='text-left text-base  leading-none text-gray-100'>
        {children}
      </span>
    </IconWrapper>
  );
};

const DayCell = ({ children }) => {
  return <td className='text-left w-auto pr-2'>{children}</td>;
};

const HoursCell = ({ children }) => {
  return <td className='text-left w-auto'>{children}</td>;
};

export const HoursTable = ({ data }) => {
  return (
    <table className='text-white p-0 h-auto'>
      <tbody>
        <tr>
          <DayCell>Monday: </DayCell>
          <HoursCell>{data.MONDAY}</HoursCell>
        </tr>
        <tr>
          <DayCell>Tuesday: </DayCell>
          <HoursCell>{data.TUESDAY}</HoursCell>
        </tr>
        <tr>
          <DayCell>Wednesday: </DayCell>
          <HoursCell>{data.WEDNESDAY}</HoursCell>
        </tr>
        <tr>
          <DayCell>Thursday: </DayCell>
          <HoursCell>{data.THURSDAY}</HoursCell>
        </tr>
        <tr>
          <DayCell>Friday: </DayCell>
          <HoursCell>{data.FRIDAY}</HoursCell>
        </tr>
        <tr>
          <DayCell>Saturday: </DayCell>
          <HoursCell>{data.SATURDAY}</HoursCell>
        </tr>
        <tr>
          <DayCell>Sunday: </DayCell>
          <HoursCell>{data.SUNDAY}</HoursCell>
        </tr>
      </tbody>
    </table>
  );
};

export const AddressWrapper = ({ children }) => {
  return (
    <div className='flex flex-col space-y-2 whitespace-nowrap'>{children}</div>
  );
};
