import React from 'react';
import AppleStore from './AppleStore';
import GooglePlay from './GooglePlay';
export default function DownloadLink() {
  return (
    <div className>
      <h3 className='text-white text-xl mb-4'>
        <span className='font-bi'>LC Brazilian Jiu-Jitsu</span> app
      </h3>
      <div className='flex items-center'>
        <div>
          <img
            src='images/lcbjjAppIcon.webp'
            className='rounded-lg'
            alt='glofox-logo'
            width='60'
            height='60'
          />
        </div>
        <div>
          <h1 className='text-white text-2xl font-semibold ml-5'>
            is available on
          </h1>
        </div>
      </div>
      <div className='flex items-center mt-7'>
        <div>
          <AppleStore />
        </div>
        <div className='ml-8'>
          <GooglePlay />
        </div>
      </div>
    </div>
  );
}
