export default function Affiliations() {
  const associations = [
    '/images/logos/ibjjf.png',
    '/images/logos/uaejj.png',
    '/images/logos/ukbjja.png',
  ];

  return (
    <div className="flex flex-col items-center w-full gap-40 my-24  mx-auto max-w-[90vw] lg:max-w-[800px]">
      <div className="flex items-center justify-around w-full ">
        {associations.map((association, index) => {
          return (
            <img
              key={index + 'association'}
              src={association}
              className="h-24 mx-4 "
              alt="association"
            />
          );
        })}
      </div>
    </div>
  );
}
