import React from 'react';
import { faqData } from '../data/questions';
const FAQComponent = ({ item }) => {
  return (
    <div className='flex justify-start items-start flex-col'>
      <div>
        <h3 className='text-gray-800  text-xl md:text-2xl font-semibold tracking-tighter'>
          {item.question}
        </h3>
      </div>
      <div className='text-gray-600 mt-3 text-sm md:text-base leading-normal'>
        <p>{item.answer}</p>
      </div>
    </div>
  );
};

export default function FAQs() {
  return (
    <div className=''>
      <section className='py-20 bg-white '>
        <div className='mx-auto container lg:px-10 xl:px-0'>
          <div className='flex justify-center items-center flex-col'>
            <div className='text-center flex items-center justify-center'>
              <h2 className='text-gray-800 md:text-6xl text-4xl font-black'>
                Frequently asked questions
              </h2>
            </div>
            <div className='px-10 lg:px-0 grid lg:grid-cols-2 grid-cols-1 lg:justify-between justify-center items-start md:mt-28 mt-16 lg:gap-x-32 md:gap-y-14 gap-y-7'>
              {faqData.map((item, index) => {
                return <FAQComponent key={index} item={item} />;
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
