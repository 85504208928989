import React from 'react';

export default function Error() {
  return (
    <div className='px-4 md:px-10 pt-6 md:pt-12 md:pb-4 pb-7 flex flex-col justify-center items-center min-h-[300px]'>
      <h1 className='text-2xl font-b'>Something went wrong</h1>
      <h5 className='font-r'>
        There was a problem sending your message. Please try again.
      </h5>
    </div>
  );
}
