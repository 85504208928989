import React from 'react';
import Map from '../components/LeafletMap';
import FindUs from '../components/FindUs';

export default function Location() {
  return (
    <div className="w-full h-full min-h-[90vh] relative mt-16">
      <Map />
      <FindUs />
    </div>
  );
}
