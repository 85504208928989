import React from 'react';
export default function Timetable() {
  return (
    <div className='w-full mt-12'>
      <iframe
        src='https://app.glofox.com/portal/#/branch/621e40b61301c306c6108933/classes-week-view'
        frameBorder='0'
        width='100%'
        height='1400'
        title='Timetable'
      ></iframe>
    </div>
  );
}
