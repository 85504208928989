import React from 'react';
import Quote from '../components/Quote';
import {
  firstText,
  secondText,
  firstQuoteText,
  lucianoCompTitle,
  lucianoCompetitions,
  INSTRUCTORS,
} from '../data/aboutPageText';
import MedalCTA from '../components/MedalCTA';
import CTARowRev from '../components/CTARowRev';
import LucianoInstructor from '../components/LucianoInstructor';
import Instructor from '../components/Instructor';
const SectionHeader = ({ name, teaches }) => {
  return (
    <div className="pt-20 pb-8 my-4 sm:pt-16 xl:mx-auto xl:container xl:px-24 md:px-6">
      <div className="mx-auto">
        <div className="flex flex-col items-center justify-center w-full space-y-4 md:flex-row md:space-x-4 md:space-y-0">
          <h1 className="text-5xl md:text-6xl font-xb">{name}</h1>
          <h3 className="text-4xl md:text-6xl font-mi">{teaches}</h3>
        </div>
      </div>
    </div>
  );
};

export default function About() {
  return (
    <div className="w-full mt-4">
      <div className="bg-white ">
        <SectionHeader name="Luciano" teaches="Black Belt" />
        <LucianoInstructor
          image="images/lucianoHS.JPG"
          header={firstText}
          description={secondText}
        />
        <MedalCTA
          image="images/luciano_comp.png"
          title={lucianoCompTitle}
          competitions={lucianoCompetitions}
        />
        <Quote
          text={firstQuoteText}
          image="images/blackGi.JPG"
          name="Luciano"
        />
        <div className="instructorsWrapper">
          {INSTRUCTORS.map((instructor, index) => {
            return (
              <div key={index}>
                <SectionHeader
                  name={instructor.name}
                  teaches={instructor.teaches}
                />
                <Instructor
                  index={index}
                  copyOne={instructor.copyOne}
                  copyTwo={instructor.copyTwo}
                  image={instructor.image}
                  id={instructor.id}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
