import React from 'react';

export default function AppMockUp() {
  return (
    <div className='mx-auto container'>
      <svg
        width='400'
        height='753'
        viewBox='0 0 400 753'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='absolute hidden xl:block -mt-12 w-[200px] h-[375px]'
      >
        <g clip-path='url(#clip0_4_55)'>
          <path
            d='M181.742 744.238H185.889L188.074 741.141H191.686H208.393H212.004L214.192 744.238H218.336H325.299C358.958 744.238 386.078 717.115 386.078 683.457V68.7793C386.078 35.1206 358.957 8 325.299 8H74.7793C41.1206 8 14 35.1206 14 68.7793V683.457C14 717.115 41.1202 744.238 74.7793 744.238H181.742Z'
            fill='white'
            stroke='#a1a1a1'
            stroke-width='16'
          />
          <path
            d='M266 5H133C126.925 5 122 9.92487 122 16V21C122 27.0751 126.925 32 133 32H266C272.075 32 277 27.0751 277 21V16C277 9.92487 272.075 5 266 5Z'
            fill='#a1a1a1'
          />
          <path
            d='M400 162.5C400 158.91 397.09 156 393.5 156C389.91 156 387 158.91 387 162.5V249.5C387 253.09 389.91 256 393.5 256C397.09 256 400 253.09 400 249.5V162.5Z'
            fill='#a1a1a1'
          />
          <path
            d='M13 248.5C13 244.91 10.0899 242 6.5 242C2.91015 242 0 244.91 0 248.5V303.5C0 307.09 2.91015 310 6.5 310C10.0899 310 13 307.09 13 303.5V248.5Z'
            fill='#a1a1a1'
          />
          <path
            d='M13 162.5C13 158.91 10.0899 156 6.5 156C2.91015 156 0 158.91 0 162.5V217.5C0 221.09 2.91015 224 6.5 224C10.0899 224 13 221.09 13 217.5V162.5Z'
            fill='#a1a1a1'
          />
          <path
            d='M13 93.5C13 89.9101 10.0899 87 6.5 87C2.91015 87 0 89.9101 0 93.5V115.5C0 119.09 2.91015 122 6.5 122C10.0899 122 13 119.09 13 115.5V93.5Z'
            fill='#a1a1a1'
          />
          <path
            d='M199.162 216.931C198.496 218.148 179.711 252.445 157.413 293.148C135.127 333.852 116.905 367.173 116.928 367.195C116.951 367.218 122.52 363.889 129.294 359.801L141.614 352.372L174.625 290.76C192.778 256.877 207.636 229.102 207.636 229.033C207.636 228.918 201.447 216.667 200.712 215.323L200.379 214.703L199.162 216.931Z'
            fill='black'
          />
          <path
            d='M212.102 240.446C212.136 241.927 212.274 249.655 212.412 257.612L212.653 272.079L215.753 277.935C217.464 281.15 218.888 283.825 218.922 283.871C218.968 283.917 222.057 282.332 225.777 280.346C229.509 278.36 232.597 276.718 232.655 276.695C232.735 276.672 213.698 240.63 212.435 238.437L212.033 237.748L212.102 240.446Z'
            fill='black'
          />
          <path
            d='M185.269 289.612C171.341 317.984 159.962 341.212 159.985 341.235C160.043 341.292 184.293 326.687 184.511 326.457C184.614 326.354 190.688 314.057 198.014 299.119L211.333 271.964L211.023 255.051C210.839 245.751 210.678 238.115 210.655 238.081C210.621 238.058 199.208 261.251 185.269 289.612Z'
            fill='black'
          />
          <path
            d='M172.512 335.333C165.451 339.593 159.652 343.106 159.606 343.141C159.572 343.175 171.708 343.21 186.578 343.21H213.606L217.567 335.494C219.749 331.257 221.552 327.744 221.563 327.686C221.586 327.64 213.445 327.594 203.479 327.594H185.36L172.512 335.333Z'
            fill='black'
          />
          <path
            d='M249.12 335.379L245.136 343.152L258.524 343.187C265.895 343.198 271.935 343.198 271.958 343.175C271.98 343.152 270.132 339.639 267.847 335.368L263.69 327.594H258.397H253.104L249.12 335.379Z'
            fill='black'
          />
          <path
            d='M243.689 352.832L240.129 352.866L236.226 360.49C234.078 364.681 232.299 368.206 232.276 368.309C232.241 368.493 233.757 368.504 258.799 368.424C273.416 368.378 285.38 368.321 285.403 368.309C285.414 368.286 283.566 364.773 281.281 360.502L277.136 352.74L262.186 352.763C253.977 352.786 245.652 352.809 243.689 352.832Z'
            fill='black'
          />
          <path
            d='M130.603 360.593C123.542 364.853 117.732 368.367 117.697 368.401C117.663 368.436 136.309 368.47 159.136 368.47H200.643L204.593 360.766C206.774 356.529 208.577 353.015 208.589 352.958C208.611 352.9 195.488 352.854 176.037 352.854H143.451L130.603 360.593Z'
            fill='black'
          />
          <path
            d='M220.905 335.496L217.211 343.274L229.624 343.308C236.458 343.32 242.313 343.331 242.334 343.308C250.75 327.707 250.75 327.707 249.085 331.211L250.75 327.707H229.506H224.599L220.905 335.496Z'
            fill='#932933'
          />
          <path
            d='M207.483 360.529L203.789 368.307L216.202 368.341C223.036 368.353 228.891 368.364 228.913 368.341C237.329 352.74 237.329 352.74 235.664 356.244L237.329 352.74H216.085H211.177L207.483 360.529Z'
            fill='#932933'
          />
        </g>
        <defs>
          <clipPath id='clip0_4_55'>
            <rect width='400' height='753' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
